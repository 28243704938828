/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
// @import '~dropzone/dist/min/dropzone.min.css';
// @import "@progress/kendo-theme-default/scss/dist/all.scss";

// @import "@progress/kendo-theme-bootstrap/dist/all.scss";
// @import "~bootstrap/scss/bootstrap.scss";

$kendo-color-primary: #2387C8;


@import "@progress/kendo-theme-default/scss/all.scss";

@import 'dropzone/dist/min/dropzone.min.css';


.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

ngx-dropzone {
  border: 2px dashed var(--bs-border-color) !important;
}


// Kendo Grid Css //

.k-header {
  background: $kendo-color-primary !important;
  color: #fff !important;
}

.k-grid-header-menu {
  color: #fff !important;
}

.k-grid-header .k-grid-header-menu:hover {
  background: $kendo-color-primary !important;
}

// Kendo Grid Css End //

// Custom stepper Css //

.custom-steeper .stepper .stepper-header .steps {
  width: 35% !important;
  cursor: pointer;
}

.custom-steeper .stepper .stepper-body {
  padding: 0rem !important;
}

.custom-steeper-paddding .stepper .stepper-body {
  padding: 0rem !important;
}

.stepper .stepper-body {
  margin: 15px auto !important;
  padding: 0px !important;
}

// Custom stepper Css End //


// Breadcrumb Css //

.breadcrumb-item.active {
  font-weight: 600 !important;
}

// Breadcrumb Css End //

// Accordian Css //

.custom-accordian .panel.accordion-item .accordion-toggle {
  display: flex;
  justify-content: space-between;
}

.custom-accordian .panel .card .card-header {
  padding: 5px 20px !important;
}

.panel.accordion-item.panel-open .card-header {
  background-color: #E6F5FF !important;
}

.custom-accordian-bill .panel.accordion-item.panel-open .card-header {
  background-color: #fff !important;
}



.panel-group .card .card-header {
  padding: 5px 20px !important;
}

.panel.accordion-item.panel-open {

  .btn-link {
    color: var(--bs-body-color) !important;
    margin-top: 5px;
    font-size: 1rem;
  }

  .panel-title {
    color: $kendo-color-primary !important;

  }

}

.text-accordian {
  color: #626262 !important;
}

.custom-accordian-bill .panel .panel-default {
  box-shadow: 0px 2px 12px 2px #E5E9FC
}

.custom-accordian-bill .panel .card .card-header {
  padding-left: 8px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

// Accodian Css End //

//
// Dropzone
//

.dropzone {
  min-height: 230px !important;
  border: 2px dashed #eff2f7 !important;
  background: #fff !important;
  border-radius: 6px !important;
  text-align: center;

  .dz-message {
    width: 100%;
    margin: 1em 0;
  }
}

[data-dz-thumbnail] {
  width: 100%;
  height: 100%;
}

.dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone .dz-preview .dz-image img {
  display: block;
  height: 90px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone .dz-preview .dz-remove {
  margin-top: 10px;
}

dropzone>.dropzone.dz-wrapper .dz-message {
  background-color: transparent !important;
  border: none !important;
  overflow: inherit !important;
  width: inherit !important;
  min-width: inherit !important;
  min-height: 180px !important;
  max-width: inherit !important;
  max-height: inherit !important;
  margin: 0px !important;
}

.dz-text {
  font-size: 1.21875rem
}

dropzone>.dropzone.dz-wrapper {
  min-height: 76px !important;
  border: none !important;
}


#component-colorpicker {

  color-picker {
    .open {
      right: 0px !important;
      left: auto !important;
      z-index: 9999 !important;
    }
  }
}

.color-picker {
  .arrow.arrow-bottom {
    right: 20px !important;
    left: auto !important;
  }
}

.switch.switch-small>.switch-pane>span {
  font-size: 12px !important;
}

.modal-dialog {
  top: auto !important;
  bottom: auto !important;
}

.slick-dots {
  margin-top: 20px;
  bottom: -40px !important;


  li.slick-active button:before {
    color: $kendo-color-primary !important;
    font-size: x-small !important;
  }
}

.slick-dots li button:before {
  color: $kendo-color-primary;
  font-size: x-small !important;
}

.slick-slide img {
  width: 100%;
}

.dropup {
  .dropdown-menu {
    inset: auto auto auto 0px !important;
    transform: translateY(-160px) !important;
  }
}

.ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background-color: $kendo-color-primary !important;
}

.w-60 {
  width: 60% !important;
}

.navbar-brand-box {
  padding: 0rem !important;
}

.c-mr-1 {
  margin-right: 0.5rem;
}

.mdi-attachment-rotate {
  transform: rotate(315deg);
}

.cursor-move {
  cursor: move;
}

.cursor-pointer {
  cursor: pointer;
}

.c-border {
  border: 1px solid #fff;
}

.custom-calender-icon {
  position: absolute;
  top: 40px;
  right: 25px;
}

.custom-grid-btn {
  padding: 5px 10px !important;
  display: flex !important;
  align-items: baseline !important;
}

.btn-white {
  background-color: #fff;
  color: #000;
  width: 100%;
}

.btn-white:hover {
  background: #efefef;
  color: #000;
}

.utility-img {
  background: #EEF1FD;
  padding: 5px;
  width: 30px;
  border-radius: 5px;
  margin-right: 10px;

}

.custom-bg-body {
  background-color: var(--bs-body-bg);
}

.w-3 {
  width: 3%;
  height: 0%;
}

.w-80 {
  width: 80%;
}

.form-label {
  margin-bottom: 0.2rem !important;
}

.custom-table {
  border: 1px solid #A6B0CF !important;

  thead tr th {
    background-color: #EEF1FD;
  }

  tbody tr td {
    background-color: #EEF1FD;
  }
}

.custom-list-bill {
  background: #EEF1FD;
  border: 1px solid #A6B0CF !important;

  .custom-padding {
    padding: 0px 15px 0px 15px;
  }
}

.custom-list-bill-danger {
  background: #ffe4e4;
  border: 1px solid #f68585 !important;

  .custom-padding {
    padding: 0px 15px 0px 15px;
  }
}

.custom-list-bill-view {
  background: #E6F4F1;
}

.border-right {
  border-right: 1px solid #A6B0CF !important;
}

.bg-grey {
  background-color: #8D8DB9 !important;
}

.height-25 {
  height: 25px;
}

.underline {
  border-bottom: 2px solid $kendo-color-primary;
  margin: 3px 0px 10px 0px !important
}

.underline-danger {
  border-bottom: 2px solid rgba(244, 106, 106, 1);
  margin: 3px 0px 10px 0px !important
}

.mt-8 {
  margin-top: 8.4rem
}

@media only screen and (max-width: 600px) {
  .logo-sm {
    padding: 0px 5px 0px 5px !important;
  }

  .page-title-right {
    margin-top: 10px;
  }
}

// Media Query For Mobile Devices //

@media (max-width: 992px) {
  .logo span.logo-sm {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {

  .k-grid .k-grid-aria-root {
    overflow: auto !important;
  }

  .k-grid .k-table {
    table-layout: auto !important;
  }

  .custom-steeper .stepper .stepper-header .steps {
    width: 100% !important;
  }

  .sm-btn {
    margin-bottom: 1rem;
    justify-content: end !important;
  }

  .sm-device {
    display: flex;
    justify-content: end;
    width: 100%;
  }

  .page-title-right {
    margin-top: 1rem;
  }

  // Stepper Css //

  .stepper-body {
    margin: 5px auto !important;
    padding: 0.875rem !important;
  }

  .sm-stepper .stepper .stepper-header {
    display: none !important;
  }

}

// Media Query For Mobile Devices //


@media print {

  .print-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  // .content-view {
  //   page-break-after: always
  // }

  //   .content-view:last-child {
  //     page-break-after: auto;
  // }


  .print-multi-bottom {
    position: relative;
    bottom: 0;
    width: 100%;
    text-align: center;
  }


  @page: first {
    @bottom-left {
      content: none;
    }

    @bottom-center {
      content: none;
    }

    @bottom-right {
      content: none;
    }

  }

  // .print-multi-bottom {
  //   display: block; 
  // }


  .bg-grey {
    background-color: #8D8DB9 !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .custom-list-bill-view {
    background: #E6F4F1;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .custom-list-bill {
    background: #EEF1FD;
    border: 1px solid #A6B0CF !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .bg-primary {
    background: $kendo-color-primary !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .col-lg-6 {
    width: 50%;
  }

  html {
    overflow: hidden;
  }
}

// Kendo Dropdown CSS //

.kendo-form-control .button {
  background-color: var(--bs-secondary-bg);
}

.kendo-form-control .k-input-inner {

  width: 100%;
  padding: 0.47rem 0.75rem !important;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-emphasis-color);
  appearance: none;
  background-color: var(--bs-secondary-bg);
  background-clip: padding-box;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}

// Kendo Dropdown CSS End //

.mt-50 {
  margin-top: 50%;
}

.mt-100 {
  margin-top: 100%;
}



.app-search .form-control {
  border: none;
  height: 38px;
  padding-left: 40px;
  padding-right: 20px;
  background-color: #fff !important;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: none;
  border-radius: 0.25rem !important;
}

.app-search {
  padding: calc(12px * 0.5) 0 !important;
}

.text-blue {
  color: #2387C8;
}

.border-bottom {
  border-bottom: 1px solid #2387C8;
  padding-bottom: 10px;
}

.k-grid-header .k-sort-icon {
  color: white !important;
  /* Change to your desired color */
}

.k-grid-header .k-grid-filter.k-active,
.k-grid-header .k-header-column-menu.k-active,
.k-grid-header .k-grid-header-menu.k-active,
.k-grid-header .k-hierarchy-cell .k-icon.k-active {
  color: #8c8c8c !important;
  background-color: #fcfcfd !important;
}

.images-view img {
  // width: 10%;
}

.mini-stats-wid-border {
  border: 2px dashed #cecece;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.25);
}

.mini-stats-wid-dashboard {
  margin-top: -35px;

  .card-details {
    margin-top: 2.5rem;
  }
}


.font-weight-bolder {
  font-weight: 500;
}

.project-image {
  width: 15%;
  margin-right: 5px;
}

.dashboard-image {
  background: #fff;
  border-radius: 50%;
  padding: 5px;
  z-index: 1;
  width: 22%;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.25) !important;
}

.dateRange {
  padding: 0.35rem 0.75rem !important;


}

.input-container {
  position: relative;
  display: inline-block;
}

.input-container input {
  padding-right: 30px;
}

.input-container::after {
  content: '\e9c2';
  position: absolute;
  font-family: boxicons !important;
  font-weight: 400;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  cursor: pointer;
}

.border-radius-sm {
  border-radius: 8px;
}

.font-weight-bold {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.rp-border-right {
  border-right: 2px dashed #50A9D1;
}

.rp-icon {
  width: 30%;
  background: white;
  padding: 4px;
  border-radius: 50%;
}

.bg-gas-subtle {
  background-color: #FFFBD4;
  border: 1px solid #FFFBD4;
  border-radius: 3px;
}


.bg-electricity-subtle {
  background-color: #D7FFE3;
  border: 1px solid #D7FFE3;
  border-radius: 3px;
}

.bg-water-subtle {
  background-color: #DFEDFF;
  border: 1px solid #DFEDFF;
  border-radius: 3px;
}

.bg-ev-subtle {
  background-color: #FFFBD4;
  border: 1px solid #FFFBD4;
  border-radius: 3px;
}

.bg-subtle {
  // background: #FFF8F9;
  // border: 1px solid #FFE2E5;
  border-radius: 3px;
}

.hr {
  border-style: solid;
  border-width: thin;
  border-color: #e0e0e0;
  margin: 0 !important;
}

.mt-custom {
  margin-bottom: 17rem
}

.hr-center {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #d7d7d7;
  line-height: 0.1em;
  margin: 20px 0 0px;

}

.hr-center span {
  background: #fff;
  padding: 0 10px;
}

.modal-content {
  border: none !important;
}

.k-grid td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.swal2-icon {
  font-size: 7px !important;
}

.swal2-title {
  // width: 31%;
  width: unset !important;
}

.custom-border-table {
  border: 1px solid #c0f8ec; // Set the border color and thickness
  margin-top: 2rem;

  // th, td {
  //   border: 1px solid transparent;  // Optional: Add border to individual cells
  // }

  thead {
    tr:first-child {
      border-bottom: 1px solid #c0f8ec;

    }

    tbody {

      tr:first-child {
        border-bottom: 1px solid transparent !important;
      }

      tr:nth-child(2) {
        border-bottom: 1px solid #c0f8ec;
      }
    }
  }
}

.totalBill {
  border: 1px solid #c0f8ec;
  padding: 7px;
  border-radius: 5px;
}

.font-weight-100 {
  font-weight: 100 !important;
}

.penaltyBill {
  padding: 20px;
  background: #FFE2E5;
  border: 2px solid #e99d9d;
  border-radius: 5px;
  margin-top: 1rem;

  div p {
    margin-bottom: 0px;
  }
}

.page-title-box {
  padding-bottom: 15px !important;
}

.page-title-box .breadcrumb {
  font-size: 14px !important;
}

.page-content {
  padding: calc(70px + 10px) calc(24px* 0.75) 60px calc(24px* 0.75) !important;
}

.breadcrumb {
  .btn-primary {
    .fa-file-excel {
      margin-right: 5px;
      font-size: 14px;
    }
  }
}

label {
  font-size: 15px;
}

.border-left-none {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.right-side-panel {
  position: fixed;
  top: 4.5rem;
  right: -50rem;
  height: 100%;
  width: 40rem;
  background-color: #fff;
  box-shadow: 0px 0 1px rgba(0, 0, 0, 0.5);
  // transition: right 0.3s;
  cursor: pointer;
  z-index: 111;
  transition: right 1s ease;
}


.right-side-panel.open {
  right: 0rem;
  /* Slide the panel into view */
}


.right-side-panel .content {
  flex-grow: 1;
  overflow-y: auto;
  height: 100vh;
  height: calc(100vh - 120px);
  border-left: 2px solid #ccc;

}

.sidemenuimages {
  width: 20px;
  padding: 2px;
}










.right-side-panel {
  width: 40rem !important;
  background-color: white !important;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1) !important;
  transition: right 2s ease;

}

td.k-detail-cell.k-table-td {
  padding: 0 !important;
}

td.k-detail-cell.k-table-td app-wallet-history-expended {
  padding: initial;
  /* Or set any required padding for the content inside */
}

// .right-side-panel .notification-item {
//   transition: background-color 0.2s ease-in-out !important;
// }

// .right-side-panel .notification-item:hover {
//   background-color: #f1f3f5 !important;
// }

.h-290 {
  height: 290px !important;
}

.h-250 {
  height: 250px;
}

.h-275 {
  height: 275px;
}

.k-grid .k-grid-content td {
  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow: hidden;
  /* Hide overflow text */
  text-overflow: ellipsis;
  /* Add ellipsis for overflow text */
  max-width: 150px;
  /* Set a max width for the cells */
}

.w-30 {
  width: 30% !important;
}

.hiddennumber {
  display: none !important;
}

.step {
  padding: 15px !important;
  max-height: 50px !important;
  border-left: 0.3px dotted #d3e7f4 !important;
  border-right: 0.3px dotted #d3e7f4 !important;
}

.login-check {
  border: 1px solid #2387C8;
}

.text-dark-secondary {
  color: #2a3042;
}

.stepper-header .steps .step {
  cursor: pointer;
}

.stepper-header .steps .step.active {
  font-size: 1.1rem;
  color: #2387C8;
  border-bottom: 3px solid;
  border-left: 1px solid #ccc !important;
  border-right: 1px solid #ccc !important;
  box-shadow: -4px 4px 5px -4px #ccc, 0px -7px 7px 0px #ccc;
  cursor: pointer;
}

.search_icon {
  .k-textbox {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .btn {
    padding: 3px 12px;

  }
}

.search_icon-box {
  .k-textbox {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .btn {
    padding: 7px 12px;

  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 8px !important;
}

// .vertical-collpsed {
//   min-height: 100vh !important;
// }

.btn-close {
  --bs-btn-close-color: #fff !important;
  --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e) !important;
}

.k-pager-md .k-pager-sizes .k-dropdown,
.k-pager-md .k-pager-sizes .k-dropdownlist {
  width: 5.4em !important;
}

.header-notification {
  position: relative;
  height: auto;
  overflow-y: scroll;
  max-height: 340px;
}

.font-weight-bold-dashboard {
  font-weight: 600;
  font-size: 22px;
}

.swal2-confirm {
  background-color: $kendo-color-primary !important;
}

.inputIcon {
  position: absolute;
  right: 12px;
  top: 10px;
  font-size: 16px;
  z-index: 1;
  cursor: pointer;
}

.wizard .stepper-header .number {
  display: none !important;
}

.position-relative {
  position: relative;
}

.inputIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.clear-icon {
  right: 35px;
  /* Adjust based on the calendar icon placement */
}

.card-no-data {
  height: 281px;
}

.meter-no-data {
  height: 20rem
}

.consumer-no-data {
  height: 22.5rem
}

.residential-no-data {
  height: 22.5rem
}

.meterStatus-no-data {
  height: 20rem
}

.bills-no-data {
  height: 280px;
}

.connection-no-data {
  height: 326px;
}



.invalid-feedback {
  font-size: 13px !important;
}

.fa-bell {
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 5px;
}

.mb-10 {
  margin-bottom: 10rem;
}


.ng-select-shadow {
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.25) !important
}

.k-input-inner::placeholder {
  color: #74788d !important;
  font-size: 0.8125rem !important;
}

input::placeholder {
  color: #74788d !important;
  font-size: 0.8125rem !important;
}

.prepaid-dashboard-image {
  background: #fff;
  border-radius: 50%;
  z-index: 1;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.25) !important;
  height: 70px;
  width: 70px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 50px;
  }
}


.k-dropzone-hint {
  margin-left: 10px !important;
}

.ng-select .ng-clear-wrapper {
  cursor: pointer;
  position: relative;
  width: 25px;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-280 {
  height: 280px;
}

.gasBg {
  background-color: #FFE2E5;
}

.waterBg {
  background-color: #F3E8FF;
}

.electricityBg {
  background-color: #FFF4DE;
}

.managCard {
  height: 192px;
  overflow-y: auto;
}

.ellipsis {
  display: inline-block;
  /* Ensure the span behaves like a block */
  max-width: 70px;
  /* Set the maximum width you want */
  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow: hidden;
  /* Hide overflow text */
  text-overflow: ellipsis;
  /* Show ellipsis (...) */
}



//clear icon on kendo search 

::ng-deep .search-container {
  position: relative;
  display: inline-block;
}

::ng-deep .clear-icon {
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 16px;
  color: #888;
  z-index: 1;
}

::ng-deep .clear-icon:hover {
  color: #333;
}

::ng-deep .k-textbox {
  padding-right: 25px;
}

.invoicecard_ele {
  height: 193px;
}

.invoicecard_gas {
  height: 143px;
}

.invoicecard_Water {
  height: 150px;
}

.apexcharts-canvas svg {
  width: 100% !important
}

//end



.bill-count {

  font-size: 30px;

  font-weight: 500;

  align-self: center;

}

.bills-summary-container {

  border-radius: 0;

  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  display: flex;

  max-width: 521px;

  flex-direction: column;

  font-family: Poppins, sans-serif;

  color: rgb(42, 48, 66);

}


.summary-card-paid {

  border-radius: 12px;

  background-color: rgb(218, 255, 242);

  box-shadow: 0 10px 20px rgba(18, 38, 63, 0.031);

  display: flex;

  gap: 20px;

  flex-wrap: wrap;

  justify-content: space-between;

  padding: 30px 57px 30px 17px;

}

.summary-card-unPaid {

  border-radius: 12px;

  background-color: #FFE5E5;

  box-shadow: 0 10px 20px rgba(18, 38, 63, 0.031);

  display: flex;

  gap: 20px;

  flex-wrap: wrap;

  justify-content: space-between;

  padding: 30px 57px 30px 17px;

}

.summary-card-Total {

  border-radius: 12px;

  background-color: #FFEFCF;

  box-shadow: 0 10px 20px rgba(18, 38, 63, 0.031);

  display: flex;

  gap: 20px;

  flex-wrap: wrap;

  justify-content: space-between;

  padding: 30px 57px 30px 17px;

}

.bill-icon {

  aspect-ratio: 1;

  object-fit: contain;

  object-position: center;

  width: 85px;

}

.bill-count-wrapper {

  display: flex;

  flex-direction: column;

  margin: auto 0;

}

.bill-label {

  font-size: 18px;

  font-weight: 400;

}

.divider {

  aspect-ratio: 0.02;

  object-fit: contain;

  object-position: center;

  width: 1px;

  margin: auto 0;

}

.amount-wrapper {

  align-self: start;

  display: flex;

  margin-top: 4px;

  flex-direction: column;

}

.amount-label {

  font-size: 18px;

  font-weight: 400;

}

.amount-value {

  font-size: 30px;

  font-weight: 500;

}

@media (max-width: 991px) {

  .summary-card {

    padding-right: 20px;

  }

  .amount-value {

    margin-right: 10px;

  }

}

.table-responsive1 {
  height: 41vh !important;
  overflow: auto !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 150px !important;
}

/* .ng-dropdown-panel .ng-dropdown-panel-items .select-month-dropdown {
  height: 25vh !important;
  overflow: auto !important;

} */